import { FC, forwardRef } from 'react'
import { CloseButton, ErrorContent, ErrorHeader, RetryButton, Root } from './styled'
import { Body14 } from '@platform-ui/typography'
import { ClearIcon } from '@platform-ui/icons'

interface ReferencesActionResultProps {
  header: string
  text: string
  onRetryClick?: () => void
  onClose?: () => void
}

export const ReferencesActionResult: FC<ReferencesActionResultProps> = forwardRef<
  HTMLDivElement,
  ReferencesActionResultProps
>((props, ref) => {
  const { header, text, onRetryClick, onClose } = props

  return (
    <Root ref={ref}>
      <ErrorContent>
        <div>
          <ErrorHeader>{header}</ErrorHeader>
          <Body14>{text}</Body14>
        </div>
        {onClose && (
          <CloseButton onClick={onClose}>
            <ClearIcon color="text/tertiary" size="s16" />
          </CloseButton>
        )}
      </ErrorContent>
      {onRetryClick && (
        <RetryButton isFullWidth sizeLg="m" sizeMd="m" sizeSm="m" onClick={onRetryClick}>
          Попробовать снова
        </RetryButton>
      )}
    </Root>
  )
})
